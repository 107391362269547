<template>
  <el-form ref="form" :model="form" label-width="200px" class="elForm" :rules="rules">
    <p class="tips">以下数据可以不填，不填则表示没有此项积分，或者没有限制</p>
    <el-form-item label="注册会员成功：">
      <el-input placeholder="请输入内容" type="number" min="0" v-model="form.register_user_points" style="width: 700px">
        <template slot="append">积分</template>
      </el-input>
    </el-form-item>
    <el-form-item label="完善个人资料：">
      <el-input placeholder="请输入内容" type="number" min="0" v-model="form.edit_user_points" style="width: 700px">
        <template slot="append">积分</template>
      </el-input>
    </el-form-item>
    <el-form-item label="手机验证：">
      <el-input placeholder="请输入内容" type="number" min="0" v-model="form.mobile_verify_points" style="width: 700px">
        <template slot="append">积分</template>
      </el-input>
    </el-form-item>
    <el-form-item label="邮箱验证：">
      <el-input placeholder="请输入内容" type="number" min="0" v-model="form.email_verify_points" style="width: 700px">
        <template slot="append">积分</template>
      </el-input>
    </el-form-item>
    <el-form-item label="每日登录：">
      <el-input placeholder="请输入内容" type="number" min="0" v-model="form.everyday_login_points" style="width: 700px">
        <template slot="append">积分</template>
      </el-input>
    </el-form-item>
    <el-form-item label="开通/充值VIP会员：">
      <el-input placeholder="请输入内容" type="number" min="0" v-model="form.pay_user_vip_points" style="width: 700px">
        <template slot="append">积分</template>
      </el-input>
    </el-form-item>
    <el-form-item label="邀请用户并首次消费：">
      <span class="spanMagin">固定积分</span>
      <el-input placeholder="请输入内容" type="number" min="0" v-model="form.invite_user_order_points" style="width: 200px">
        <template slot="append">积分</template>
      </el-input>
      <span class="spanMaginFoot">+首次订单积分（以好友成功消费所获积分为准）</span>
      <br /><br />
      <span class="spanMagin">每个用户ID邀请好友限制</span>
      <el-input placeholder="请输入内容" type="number" min="0" v-model="form.invite_user_limit" style="width: 530px">
        <template slot="append">个</template>
      </el-input>
    </el-form-item>
    <el-form-item label="商品评论送积分：">
      <el-input placeholder="请输入内容" type="number" min="0" v-model="form.goods_comment_points" style="width: 706px">
        <template slot="append">积分</template>
      </el-input>
    </el-form-item>
    <el-form-item label="普通用户购物送积分：">
      <span class="spanMagin">订单支付金额（扣除运费）：积分数量为</span>
      <el-input placeholder="请输入内容" type="number" min="1" v-model="form.user_shopping_points.integral" style="width: 214px"></el-input>
      ：
      <el-input placeholder="请输入内容" type="number" min="0.1" step="0.1" v-model="form.user_shopping_points.exchange" style="width: 214px"></el-input>
    </el-form-item>
    <el-form-item label="VIP用户购物送积分：">
      <span class="spanMagin">订单支付金额（扣除运费）：积分数量为</span>
      <el-input placeholder="请输入内容" type="number" min="1" v-model="form.vip_shopping_points.integral" style="width: 214px"></el-input>
      ：
      <el-input placeholder="请输入内容" type="number" min="0.1" step="0.1" v-model="form.vip_shopping_points.exchange" style="width: 214px"></el-input>
    </el-form-item>
    <el-form-item label="普通用户服务送积分：">
      <span class="spanMagin" style="margin-right: 104px;">订单支付金额：积分数量为</span>
      <el-input placeholder="请输入内容" type="number" min="1" v-model="form.user_service_points.integral" style="width: 214px"></el-input>
      ：
      <el-input placeholder="请输入内容" type="number" min="0.1" step="0.1" v-model="form.user_service_points.exchange" style="width: 214px"></el-input>
    </el-form-item>
    <el-form-item label="VIP用户服务送积分：">
      <span class="spanMagin"  style="margin-right: 104px;">订单支付金额：积分数量为</span>
      <el-input placeholder="请输入内容" type="number" min="1" v-model="form.vip_service_points.integral" style="width: 214px"></el-input>
      ：
      <el-input placeholder="请输入内容" type="number" min="0.1" step="0.1" v-model="form.vip_service_points.exchange" style="width: 214px"></el-input>
    </el-form-item>
    <el-form-item label="服务接单技工用户送积分：">
      <span class="spanMagin"  style="margin-right: 104px;">订单支付金额：积分数量为</span>
      <el-input placeholder="请输入内容" type="number" min="1" v-model="form.service_order_points.integral" style="width: 214px"></el-input>
      ：
      <el-input placeholder="请输入内容" type="number" min="0.1" step="0.1" v-model="form.service_order_points.exchange" style="width: 214px"></el-input>
    </el-form-item>
    <el-form-item label="积分兑换比例：">
      <el-input placeholder="请输入内容" type="number" min="1" v-model="form.integral_rate.integral" style="width: 214px"></el-input>
      ：
      <el-input placeholder="请输入内容" type="number" min="1" v-model="form.integral_rate.exchange" style="width: 214px"></el-input>
    </el-form-item>
    <el-form-item label="积分清零时间：">
      <el-date-picker 
      v-model="form.integral_reset_time" 
      type="datetime" 
      placeholder="选择日期时间" 
      align="right"
      format="MM-dd HH:mm:ss"
      value-format="MM-dd HH:mm:ss"
      ></el-date-picker>
    </el-form-item>
    <el-form-item label="积分规则：">
      <RichText :richTxt="form.integral_rule" @soninfo="val => (form.integral_rule = val)"></RichText>
    </el-form-item>
    <Preservation @preservation="preservation"></Preservation>
  </el-form>
</template>
<script>

import RichText from '@/components/richText';
import Preservation from '@/components/preservation';

export default {
  components: {
    RichText,
    Preservation,
  },
   data() {
    return {
      form: {
        register_user_points: 0,
        edit_user_points: 0,
        mobile_verify_points: 0,
        email_verify_points: 0,
        everyday_login_points: 0,
        pay_user_vip_points: 0,
        invite_user_order_points: 0,
        invite_user_limit: 0,
        goods_comment_points: 0,
        user_shopping_points: {
          integral: 0,
          exchange: 0,
        },
        vip_shopping_points: {
          integral: 0,
          exchange: 0,
        },
        user_service_points: {
          integral: 0,
          exchange: 0,
        },
        vip_service_points: {
          integral: 0,
          exchange: 0,
        },
        service_order_points: {
          integral: 0,
          exchange: 0,
        },
        integral_rate: {
          integral: 0,
          exchange: 0,
        },
        integral_reset_time: '',
        integral_rule: '',
        },
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo(){
            this.$axios.post(this.$api.repair.IntegralSet.info, this.user_form).then(res => {
            if (res.code == 0) {
                if(res.result){
                    this.form = res.result;
                    this.form.user_shopping_points = JSON.parse(this.form.user_shopping_points);
                    this.form.vip_shopping_points = JSON.parse(this.form.vip_shopping_points);
                    this.form.user_service_points = JSON.parse(this.form.user_service_points);
                    this.form.vip_service_points = JSON.parse(this.form.vip_service_points);
                    this.form.service_order_points = JSON.parse(this.form.service_order_points);
                    this.form.integral_rate = JSON.parse(this.form.integral_rate);
                }
            }else{
                this.$message.error(res.msg);
            }
          });
      },
      preservation() {
        if (!this.form.integral_rule) return this.$message.warning('请添加积分规则');
        let data = {
          register_user_points: this.form.register_user_points,
          edit_user_points: this.form.edit_user_points,
          mobile_verify_points: this.form.mobile_verify_points,
          email_verify_points: this.form.email_verify_points,
          everyday_login_points: this.form.everyday_login_points,
          pay_user_vip_points: this.form.pay_user_vip_points,
          invite_user_order_points: this.form.invite_user_order_points,
          invite_user_limit: this.form.invite_user_limit,
          goods_comment_points: this.form.goods_comment_points,
          user_shopping_points: this.form.user_shopping_points,
          vip_shopping_points: this.form.vip_shopping_points,
          user_service_points: this.form.user_service_points,
          vip_service_points: this.form.vip_service_points,
          service_order_points: this.form.service_order_points,
          integral_rate: this.form.integral_rate,
          integral_reset_time: this.form.integral_reset_time,
          integral_rule: this.form.integral_rule,
        };
        this.$axios.post(this.$api.repair.IntegralSet.edit, data).then(res => {
          if (res.code == 0) {
            this.$message.success('保存成功');
            this.getInfo();
          } else {
            this.$message.error(res.msg);
          }
        });
      },
    },
};
</script>

<style lang="less" scoped>

* {
  color: gray;
}
.elForm {
  background-color: #fff;
  padding: 30px;
}

.centerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tips {
  font-size: 14px;
  color: gray;
  padding: 30px 70px;
}

.spanMagin {
  margin-right: 20px;
}

.spanMaginFoot {
  margin-left: 20px;
}
</style>
